var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card-group",
        { staticClass: "mb-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "col-md-8 p-0 mr-2",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c(
                "div",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("strong", [_vm._v(" Filtros ")]),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-white float-right",
                      attrs: { variant: "primary", size: "xs" },
                      on: {
                        click: function($event) {
                          return _vm.filtrosAvancados()
                        }
                      }
                    },
                    [_vm._v("Avançado")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "p-1 mt-2" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-md-block",
                          attrs: { sm: "12", md: "5" }
                        },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  on: { change: _vm.changePeriodo },
                                  model: {
                                    value: _vm.filtro.fields.periodo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "periodo",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.periodo"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Hoje" }
                                    },
                                    [_vm._v("Hoje")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Ontem" }
                                    },
                                    [_vm._v("Ontem")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Semana" }
                                    },
                                    [_vm._v("Semana")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Mes" }
                                    },
                                    [_vm._v("Mês")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Periodo" }
                                    },
                                    [_vm._v("Período")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: { sm: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.gestao,
                                placeholder: "Todas as Gestões",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    var search = ref.search
                                    var isOpen = ref.isOpen
                                    return [
                                      values.length == 1 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opção selecionada"
                                            )
                                          ])
                                        : _vm._e(),
                                      values.length >= 2 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opções selecionadas"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.filtro.fields.gestao,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "gestao", $$v)
                                },
                                expression: "filtro.fields.gestao"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.gestao.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("gestao")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: { sm: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options:
                                  _vm.filtro.listas.centresFiltradosPorGestao,
                                placeholder: "Todas as Lojas",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    var search = ref.search
                                    var isOpen = ref.isOpen
                                    return [
                                      values.length == 1 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opção selecionada"
                                            )
                                          ])
                                        : _vm._e(),
                                      values.length >= 2 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opções selecionadas"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.filtro.fields.centres,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "centres", $$v)
                                },
                                expression: "filtro.fields.centres"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.centres.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("centres")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "4" } },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.formaVenda,
                                placeholder: "Todas as Formas de Venda",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var values = ref.values
                                    var search = ref.search
                                    var isOpen = ref.isOpen
                                    return [
                                      values.length == 1 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opção selecionada"
                                            )
                                          ])
                                        : _vm._e(),
                                      values.length >= 2 && !isOpen
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " opções selecionadas"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.filtro.fields.formaVenda,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "formaVenda", $$v)
                                },
                                expression: "filtro.fields.formaVenda"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.formaVenda.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("formaVenda")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pr-1",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { plain: true, type: "date" },
                            on: { change: _vm.dataChanged },
                            model: {
                              value: _vm.filtro.fields.dataIni,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                              },
                              expression: "filtro.fields.dataIni"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pl-1 pl-md-3",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { plain: true, type: "date" },
                            on: { change: _vm.dataChanged },
                            model: {
                              value: _vm.filtro.fields.dataFim,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                              },
                              expression: "filtro.fields.dataFim"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.filtro.filtrosAvancados
                        ? _c(
                            "b-col",
                            { attrs: { sm: "1" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "my-2 my-sm-0 text-white",
                                  class: { "btn-block": _vm.$mq === "sm" },
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.search()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-search" })]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.filtro.filtrosAvancados
                    ? _c(
                        "b-row",
                        { staticClass: "mb-1" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-md-0 pl-1 pl-md-3",
                              attrs: { cols: "6", sm: "6", md: "4" }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.filtro.listas.fornecedores,
                                    placeholder: "Todos os Fornecedores",
                                    multiple: true,
                                    "close-on-select": false,
                                    "clear-on-select": true,
                                    "show-labels": false,
                                    label: "text",
                                    "track-by": "value"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var values = ref.values
                                          var search = ref.search
                                          var isOpen = ref.isOpen
                                          return [
                                            values.length == 1 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opção selecionada"
                                                  )
                                                ])
                                              : _vm._e(),
                                            values.length >= 2 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opções selecionadas"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1659997751
                                  ),
                                  model: {
                                    value: _vm.filtro.fields.fornecedores,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "fornecedores",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.fornecedores"
                                  }
                                },
                                [
                                  _c("template", { slot: "clear" }, [
                                    _vm.filtro.fields.fornecedores.length
                                      ? _c("div", {
                                          staticClass: "multiselect__clear",
                                          on: {
                                            mousedown: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.clearAll(
                                                "fornecedores"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-md-0 pr-1 mb-1",
                              attrs: { cols: "6", sm: "6", md: "4" }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.filtro.listas.grupos,
                                    placeholder: "Todos os Grupos",
                                    multiple: true,
                                    "close-on-select": false,
                                    "clear-on-select": true,
                                    "show-labels": false,
                                    label: "text",
                                    "track-by": "value"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var values = ref.values
                                          var search = ref.search
                                          var isOpen = ref.isOpen
                                          return [
                                            values.length == 1 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opção selecionada"
                                                  )
                                                ])
                                              : _vm._e(),
                                            values.length >= 2 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opções selecionadas"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1659997751
                                  ),
                                  model: {
                                    value: _vm.filtro.fields.grupos,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filtro.fields, "grupos", $$v)
                                    },
                                    expression: "filtro.fields.grupos"
                                  }
                                },
                                [
                                  _c("template", { slot: "clear" }, [
                                    _vm.filtro.fields.grupos.length
                                      ? _c("div", {
                                          staticClass: "multiselect__clear",
                                          on: {
                                            mousedown: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.clearAll("grupos")
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "mb-1",
                              attrs: { cols: "6", sm: "6", md: "4" }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.filtro.listas.subgrupos,
                                    placeholder: "Todos os Subgrupos",
                                    multiple: true,
                                    "close-on-select": false,
                                    "clear-on-select": true,
                                    "show-labels": false,
                                    label: "text",
                                    "track-by": "value"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var values = ref.values
                                          var search = ref.search
                                          var isOpen = ref.isOpen
                                          return [
                                            values.length == 1 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opção selecionada"
                                                  )
                                                ])
                                              : _vm._e(),
                                            values.length >= 2 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opções selecionadas"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1659997751
                                  ),
                                  model: {
                                    value: _vm.filtro.fields.subgrupos,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "subgrupos",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.subgrupos"
                                  }
                                },
                                [
                                  _c("template", { slot: "clear" }, [
                                    _vm.filtro.fields.subgrupos.length
                                      ? _c("div", {
                                          staticClass: "multiselect__clear",
                                          on: {
                                            mousedown: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.clearAll("subgrupos")
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm.classe1
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "pr-md-0 pl-1 pl-md-3",
                                  attrs: { cols: "6", sm: "6", md: "4" }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.classificacoes1,
                                      placeholder:
                                        "Todos (" + _vm.classe1.text + ")",
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": true,
                                      "show-labels": false,
                                      label: "text",
                                      "track-by": "value"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var values = ref.values
                                            var search = ref.search
                                            var isOpen = ref.isOpen
                                            return [
                                              values.length == 1 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opção selecionada"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              values.length >= 2 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opções selecionadas"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1659997751
                                    ),
                                    model: {
                                      value: _vm.filtro.fields.classificacoes1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "classificacoes1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filtro.fields.classificacoes1"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.classe2
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "pr-md-0 pl-1 pl-md-3",
                                  attrs: { cols: "6", sm: "6", md: "4" }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.classificacoes2,
                                      placeholder:
                                        "Todos (" + _vm.classe2.text + ")",
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": true,
                                      "show-labels": false,
                                      label: "text",
                                      "track-by": "value"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var values = ref.values
                                            var search = ref.search
                                            var isOpen = ref.isOpen
                                            return [
                                              values.length == 1 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opção selecionada"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              values.length >= 2 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opções selecionadas"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1659997751
                                    ),
                                    model: {
                                      value: _vm.filtro.fields.classificacoes2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "classificacoes2",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filtro.fields.classificacoes2"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.classe3
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "pr-md-0 pl-1 pl-md-3",
                                  attrs: { cols: "6", sm: "6", md: "4" }
                                },
                                [
                                  _c("multiselect", {
                                    attrs: {
                                      options: _vm.classificacoes3,
                                      placeholder:
                                        "Todos (" + _vm.classe3.text + ")",
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": true,
                                      "show-labels": false,
                                      label: "text",
                                      "track-by": "value"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var values = ref.values
                                            var search = ref.search
                                            var isOpen = ref.isOpen
                                            return [
                                              values.length == 1 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opção selecionada"
                                                    )
                                                  ])
                                                : _vm._e(),
                                              values.length >= 2 && !isOpen
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(values.length) +
                                                        " opções selecionadas"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1659997751
                                    ),
                                    model: {
                                      value: _vm.filtro.fields.classificacoes3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "classificacoes3",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filtro.fields.classificacoes3"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-md-0 pl-1 pl-md-3",
                              attrs: { cols: "6", sm: "6", md: "4" }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    options: _vm.filtro.listas.vendedores,
                                    placeholder: "Todos os Vendedores",
                                    multiple: true,
                                    "close-on-select": false,
                                    "clear-on-select": true,
                                    "show-labels": false,
                                    label: "text",
                                    "track-by": "value"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var values = ref.values
                                          var search = ref.search
                                          var isOpen = ref.isOpen
                                          return [
                                            values.length == 1 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opção selecionada"
                                                  )
                                                ])
                                              : _vm._e(),
                                            values.length >= 2 && !isOpen
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(values.length) +
                                                      " opções selecionadas"
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1659997751
                                  ),
                                  model: {
                                    value: _vm.filtro.fields.vendedores,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "vendedores",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.vendedores"
                                  }
                                },
                                [
                                  _c("template", { slot: "clear" }, [
                                    _vm.filtro.fields.vendedores.length
                                      ? _c("div", {
                                          staticClass: "multiselect__clear",
                                          on: {
                                            mousedown: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.clearAll("vendedores")
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "mt-2 mb-2",
                              attrs: { sm: "12", md: "12" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-checkbox pt-1"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.filtro.fields.desconsiderarPecas,
                                        expression:
                                          "filtro.fields.desconsiderarPecas"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "chkDesconsiderarPecas"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.filtro.fields.desconsiderarPecas
                                      )
                                        ? _vm._i(
                                            _vm.filtro.fields
                                              .desconsiderarPecas,
                                            null
                                          ) > -1
                                        : _vm.filtro.fields.desconsiderarPecas
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.filtro.fields
                                              .desconsiderarPecas,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filtro.fields,
                                                "desconsiderarPecas",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filtro.fields,
                                                "desconsiderarPecas",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "desconsiderarPecas",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "chkDesconsiderarPecas" }
                                    },
                                    [
                                      _vm._v(
                                        'Desconsiderar devoluções em "Peças"'
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "mt-2 mb-2",
                              attrs: { sm: "12", md: "10", lg: "11" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-checkbox pt-1"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.filtro.fields
                                            .atendimentoPorCliente,
                                        expression:
                                          "filtro.fields.atendimentoPorCliente"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "chkAtendimentoPorCliente"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.filtro.fields.atendimentoPorCliente
                                      )
                                        ? _vm._i(
                                            _vm.filtro.fields
                                              .atendimentoPorCliente,
                                            null
                                          ) > -1
                                        : _vm.filtro.fields
                                            .atendimentoPorCliente
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.filtro.fields
                                              .atendimentoPorCliente,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filtro.fields,
                                                "atendimentoPorCliente",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filtro.fields,
                                                "atendimentoPorCliente",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "atendimentoPorCliente",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "chkAtendimentoPorCliente" }
                                    },
                                    [
                                      _vm._v(
                                        "Considerar atendimento único as vendas para o mesmo cliente"
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "1", md: "1" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "my-2 my-sm-0 text-white",
                                  class: { "btn-block": _vm.$mq === "sm" },
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.search()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-search" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "b-card",
            {
              staticClass: "col-md-4 p-0",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Layout ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-md-block", attrs: { sm: "12" } },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  model: {
                                    value: _vm.filtro.fields.exibicao,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "exibicao",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.exibicao"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Graficos" },
                                      on: {
                                        click: function($event) {
                                          _vm.loaded = true
                                        }
                                      }
                                    },
                                    [_vm._v("Gráficos")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Tabelas" }
                                    },
                                    [_vm._v("Tabelas")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.agrupamentos,
                              disabled: _vm.filtro.fields.centres.length !== 0
                            },
                            on: { change: _vm.search },
                            model: {
                              value: _vm.filtro.fields.agrupamento,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "agrupamento", $$v)
                              },
                              expression: "filtro.fields.agrupamento"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.filtro.fields.exibicao === "Tabelas"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-row",
                { key: index },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "12", lg: "12" } },
                    [
                      _c("c-table", {
                        attrs: {
                          "table-data": item.records,
                          fields: _vm.fields,
                          striped: "",
                          small: "",
                          caption: item.name,
                          "per-page": 50
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.filtro.fields.exibicao === "Graficos"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-card",
                { key: index },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                  _c(
                    "b-card-group",
                    {
                      staticClass: "card-columns mb-4",
                      attrs: { columns: "" }
                    },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mr-md-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("Vendas")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-pie-01",
                                            item.name + " - Vendas"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "Vendas")
                            ? _c("pie", {
                                attrs: {
                                  chartdata: item.charts.Vendas,
                                  height:
                                    (item.charts.Vendas.datasets[0].data
                                      .length +
                                      10) *
                                    15,
                                  moneyFormat: true,
                                  chartId: "chart-pie-01"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "Vendas")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        { staticClass: "mr-md-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("Peças")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-pie-02",
                                            item.name + " - Peças"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "Pecas")
                            ? _c("pie", {
                                attrs: {
                                  chartdata: item.charts.Pecas,
                                  height:
                                    (item.charts.Pecas.datasets[0].data.length +
                                      10) *
                                    15,
                                  chartId: "chart-pie-02"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "Pecas")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("Atendimentos")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-pie-03",
                                            item.name + " - Atendimentos"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "Atendimentos")
                            ? _c("pie", {
                                attrs: {
                                  chartdata: item.charts.Atendimentos,
                                  height:
                                    (item.charts.Atendimentos.datasets[0].data
                                      .length +
                                      10) *
                                    15,
                                  chartId: "chart-pie-03"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "Atendimentos")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-card-group",
                    { staticClass: "card-columns", attrs: { columns: "" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mr-md-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("V/P")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-bar-01",
                                            item.name + " - VP"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "VP")
                            ? _c("horizontal-bar", {
                                attrs: {
                                  chartdata: item.charts.VP,
                                  height:
                                    (item.charts.VP.datasets[0].data.length +
                                      10) *
                                    10,
                                  displayLegend: false,
                                  moneyFormat: true,
                                  chartId: "chart-bar-01"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "VP")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        { staticClass: "mr-md-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("V/A")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-bar-02",
                                            item.name + " - VA"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "VA")
                            ? _c("horizontal-bar", {
                                attrs: {
                                  chartdata: item.charts.VA,
                                  height:
                                    (item.charts.VA.datasets[0].data.length +
                                      10) *
                                    10,
                                  displayLegend: false,
                                  moneyFormat: true,
                                  chartId: "chart-bar-02"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "VA")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-black",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline mr-auto" },
                                  [_c("strong", [_vm._v("P/A")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex-inline ml-auto" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-download text-primary cursor-pointer",
                                      attrs: { title: "Exportar" },
                                      on: {
                                        click: function($event) {
                                          return _vm.export2image(
                                            "chart-bar-03",
                                            item.name + " - PA"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.loaded && _vm.displayChart(item, "PA")
                            ? _c("horizontal-bar", {
                                attrs: {
                                  chartdata: item.charts.PA,
                                  height:
                                    (item.charts.PA.datasets[0].data.length +
                                      10) *
                                    10,
                                  displayLegend: false,
                                  chartId: "chart-bar-03"
                                }
                              })
                            : _vm._e(),
                          !_vm.displayChart(item, "PA")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-secondary text-center mb-0"
                                },
                                [
                                  _vm._v(
                                    "\n            Nenhum resultado encontrado.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }