<script>
import { HorizontalBar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: HorizontalBar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    displayLegend: {
      type: Boolean,
      default: true
    },
    stacked: {
      type: Boolean,
      default: false
    },
    moneyFormat: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.renderChart(
      this.chartdata,
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.displayLegend
        },
        scales: {
          xAxes: [{
            stacked: this.stacked
          }],
          yAxes: [{
            stacked: this.stacked
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: ((tooltipItem, data) => {
              const sum = data.datasets[tooltipItem.datasetIndex].data.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr))
              const valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const percent = (100 * valor / (sum === 0 ? 1 : sum))
              const label = data.datasets[tooltipItem.datasetIndex].label;

              if (this.moneyFormat) {
                return `${label}: R$ ${Number(valor || 0).toLocaleString('pt-BR', {minimumFractionDigits: 2})} (${percent.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%)`;
              } else {
                return `${label}: ${(Number(valor) || 0).toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})} (${percent.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%)`
              }
            })
          }
        }
      }
    )
  }
}
</script>
